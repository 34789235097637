<template>
    <div class="template-page research-units grey-bg">
        <basic-page :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <transition-group name="list" tag="div" >
                <div v-for="(n, index) in this.researchData" :key="'research'+index">
                    <single-research 
                        :ppldata="peopleData"
                        :data="n"></single-research>
                </div>
            </transition-group>
        </basic-page>
    </div>
</template>

<script>
import SingleResearch from '../components/SingleResearch.vue'
import BasicPage from '../views/BasicPage'
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
export default {
    name: 'research-units',
    components: {
        SingleResearch,
        BasicPage
    },
    data: () => {
        return {
            researchData: {},
            pageData: {},
            peopleData: [],
            currentPageId: "c5964072-9161-4b55-9170-6d473ea70685",
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top'] })
            .then(res => {
                this.pageData = res[0].attributes
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })

        fetchNodes('research_unit', {
            include: ['field_ru_leader_ref', 'field_ru_member_ref']
        }).then(res => this.researchData = res)

        //PROVVISORIO
        fetchNodes('person', {
            include: ['field_person_photo']
        }).then(res => this.peopleData = res)
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
</style>