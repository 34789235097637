<template>
  <div class="module-wrapper">
    <div class="title-tile">
      <div class="main-title">
        <h3 class="module-type">Research Unit</h3>
        <h2 class="module-title">{{ this.data.attributes.title }}</h2>
      </div>
      <div class="secondary-title">
        <div>
          <h3 class="lecturer-name">{{ this.data.field_ru_member_ref.length + 1 }}</h3>
          <h4 class="lecturer-label">Members</h4>
        </div>
        <div>
          <!--<h3 class="lecturer-name">Y</h3>
          <h4 class="lecturer-label">Publications</h4>-->
        </div>
      </div>
    </div>
    <div class="content-tile ">
      <div class="people">
        <router-link :to="personLink(ruLeader)">
          <div class="image"  v-bind:style="{ backgroundImage: 'url(' + photosource(ruLeader) + ')' }">
            <!-- <img class="main-profile image-to-resize" :src="photosource(ruLeader)" 
              :alt="ruLeader.attributes.field_first_name + ' ' + ruLeader.attributes.field_last_name" 
              :title="ruLeader.attributes.field_first_name + ' ' + ruLeader.attributes.field_last_name"> -->
          </div>
        </router-link>
        <div class="main-names">
          <h3 class="name">
            {{ this.data.field_ru_leader_ref.attributes.field_first_name + ' ' + this.data.field_ru_leader_ref.attributes.field_last_name }}
          </h3>
          <div v-if="ruMembers.length>0" class="members">
            <div class="label">OTHER MEMBERS</div>
            <div>
              <router-link 
                v-for="(member, index) in ruMembers" :key="data.id+'member'+index"
                :to="personLink(member)">
                <img class="member-profile" :src="photosource(member)" 
                  :alt="member.attributes.field_first_name + ' ' + member.attributes.field_last_name"
                  :title="member.attributes.field_first_name + ' ' + member.attributes.field_last_name">
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="description" v-if="data.attributes.field_ru_description" v-html="data.attributes.field_ru_description.summary">
        Nessuna descrizione.
      </div>
      <router-link :to="researchUnitLink">
        <button class="cta dark"><span>View More</span></button>
        </router-link>
    </div>
  </div>    
</template>

<script>
import { detailsLink } from '../libs/utils'
export default {
  name: 'single-research',
  data: () => {
    return {
    }
  },
  props: {
    data: Object,
    ppldata: Array
  },
  methods: {
    photosource(ppl) {
      //console.log(ppl.attributes.field_first_name)
      if (ppl) {
        if (ppl.field_person_photo) {
          return process.env.VUE_APP_ENDPOINT + ppl.field_person_photo.attributes.uri.url
        } else {
          return '/placeholder-people.svg'
        }
      } else {
        return '/placeholder-people.svg'
      }
    },
    personLink(data) {
      if(data)
        return detailsLink('person', data)
      else return '#'
    }
  },
  computed: {
    ruLeader() {
      return this.ppldata.find(p => p.id === this.data.field_ru_leader_ref.id);
    },
    ruMembers() {
      var m = []
      this.data.field_ru_member_ref.forEach(p1 => {
        this.ppldata.forEach(p2 => {
          if(p1.id === p2.id)
            m.push(p2)
        })
      })

      //console.log(m)
      return m
    },
    researchUnitLink() {
      if(this.data)
        return detailsLink('research-unit', this.data)
      else return '#'
    }
  },
  mounted() {
    //console.log(this.ppldata)
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';

.content-tile .people .main-names .members {
  .label+div{
    display: flex;
    a {
      display: inline-flex;
    }
  }
}

.module-wrapper {
  display: flex; display: -webkit-flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
  margin:0 0 3em 0;
  width: 100%;
  min-height: 450px;
}

.title-tile {
  width: 100%;

  @media (min-width: 1200px) {
    width: 30%;
  }

  background-color: $mainColor;
  display: flex; display: -webkit-flex;
  flex-direction: column;
  position: relative;
  background-image: url("../assets/pattern-modules.svg");
  background-repeat: no-repeat;
  background-size: 70%;

  &::before {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    content: "";
    top: 20px;
    right: 20px;
    border-style: solid;
    border-color: #fff;
    border-width: 7px 7px 0px 0px;
  }

  .main-title {
    padding: 0 20px;
    padding-bottom: 10px;
    padding-top: 20%;

    @media (min-width: 992px) {
      display: flex; display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;
    }

    height: calc(100% - 7.5em);
    border-bottom: 1px #fff solid;
    
    .module-title {
      color: #000;
      padding: 0;
      margin-top: 0;
      font-size: 2.5625rem;
    }

    .module-type {
      padding: 0;
      color: #000;
      font-size: 1em;
      font-weight: 500;
      text-transform: none;
    }
  }

  .secondary-title {
    padding:2rem;
    display: flex; display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;

    .lecturer-name {
      color: #fff;
      font-size: 3em;
      padding: 0;
    }

    .lecturer-label {
      font-size: .8em;
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 2em;
    }
  }
}

.content-tile {
  padding: 3rem 4rem;
  width: 70%;
  background-color: #fff;
  margin-left: 10px;
  display: flex; display: -webkit-flex;
  flex-direction: column;

  .people {
    display: flex; display: -webkit-flex;
    flex-direction: column;
    
    @media (min-width: 576px) {
      flex-direction: row;
    }

    margin-bottom: 30px;

    .image{
      width: 180px;
      height: 180px;
      min-width:180px;
      overflow: hidden;
      background-size:cover ;
      background-position: center;
      position: relative;
      &::before{
        content: "";
        opacity: 0.5;
        background-image: url("../assets/pattern-overlay-person.png");
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        right: 0px;
        display: block;
        position: absolute;
      }
    }

    .main-profile {
      object-fit: contain;
      margin-right: 2em;
    }

    .main-names {
      display: flex; display: -webkit-flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-left: 20px;

      .name {
        color: $secondaryColor;
        text-transform: none;
        width: 100%;
        font-size: 2.125rem;
        padding-bottom: 20px;
        margin-bottom: 28px;
        border-bottom: 1px #eee solid;

        @media (max-width: 576px) {
          margin-bottom: 30px;
        }
      }
      .members {
        position: relative;
        .label {
          font-size: .9em;
          text-transform: uppercase;
          color: #999;
          margin-bottom: 10px;
        }
        &:before {
          @media (max-width: 576px) {
            top: -1.7em;
          }
        }

        .member-profile {
          width: 40px;
          height: 40px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .description {
    line-height: 2em;
    margin-bottom: 40px;
   min-height: 110px;
    overflow: hidden;
  }


}

  @media (max-width:1200px) {
    .content-tile {
      width: 100%;
      margin: 0;
      padding: 2rem 2rem ;
    }
    .title-tile {
      background-size: 40%;
      .main-title{
        height: auto;
        padding: 2rem 2rem ;
      }
      .secondary-title {
        justify-content: flex-start;
        &>div {
          margin-right: 2rem;
        }
      }
    }
  }

  @media (max-width: 576px){
    .members {
      margin-top: 1rem;
    }
    .title-tile {
      .main-title{
      padding: 3rem 1.4rem 1.4rem 1.4rem;

      .module-title {
        font-size: 1.75rem;
      }
      
    }
   
    .secondary-title {
        padding: 1rem 1.4rem 1.4rem 1.4rem;
        .lecturer-name {
          font-size: 2rem;
        }
      }
    }
    .content-tile {
      padding: 3rem 1.4rem 1.4rem 1.4rem;
    }
    .content-tile .people .main-names {
      margin-left: 0px;
      .name{
        margin-bottom: 0px;
        padding-bottom: 10px;
      }
    }
    
  }

      
</style>