//formattazione dei nomi dei specifici content type
export function slugify(string) {
  return string
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[-]+/g, "-")
    .replace(/[^\w-]+/g, "");
}

export function personName(person) {
  return person.attributes.field_first_name + " " + person.attributes.field_last_name;
}

export function convertMonth(num) {
  switch(num+1) {
    case 1: return 'January';
    case 2: return 'February';
    case 3: return 'March';
    case 4: return 'April';
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '-';
  }
}

export function convertMonthShort(num) {
  switch(num+1) {
    case 1: return 'Jan';
    case 2: return 'Feb';
    case 3: return 'Mar';
    case 4: return 'Apr';
    case 5: return 'May';
    case 6: return 'Jun';
    case 7: return 'Jul';
    case 8: return 'Aug';
    case 9: return 'Sep';
    case 10: return 'Oct';
    case 11: return 'Nov';
    case 12: return 'Dec';
    default: return '-';
  }
}

export function reWriteDate(date) {
  // formato di entrata: stringa (e.g. 2021-06-04)
  // fotmato di uscita -> 04/06/2021
  let y = date.substr(0, 4);
  let m = date.substr(5,2);
  let d = date.substr(8, 11);
  return d + "/" + m + "/" + y;
}

export function detailsLink(content_type, node) {
  let slug = ''
  if(content_type=='person')
    slug = slugify(personName(node))
  else 
    //console.log("slug",slug);
    slug = slugify(node.attributes.title);

  return `/${content_type}/${node.attributes.drupal_internal__nid}/${slug}`;
}